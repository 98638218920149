import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import StoreContext from '../../../context/StoreContext';
import ThemeContext from '../../../context/ThemeContext';

import ButtonOutline from '../../ButtonOutline';

const getPrice = (price, checkout) =>
  Intl.NumberFormat(undefined, {
    currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(parseFloat(price ? price : 0));

const Item = styled.div(
  (props) => `
  margin-bottom: ${props.theme.size.base * 8}px;
  .options {
    color: ${props.theme.color.foreground[20]};
  }
  .qty {
    margin-right: ${props.theme.size.base * 1}px; 
  }
  .product-info {
    padding: ${props.theme.size.base * 2}px 0;
    .title {
      font-size: ${props.theme.size.fontSize[5]};
      line-height: ${props.theme.size.lineHeight[5]};
      a {
        color: ${
          props.theme.dark
            ? props.theme.color.foreground[60]
            : props.theme.color.foreground[40]
        };
        text-decoration: none;
        transition: color 450ms ease;
        &:hover {
          color: ${
            props.theme.dark
              ? props.theme.color.primary[60]
              : props.theme.color.primary[40]
          };
        }
      }
    }
    .price {
      color: ${props.theme.color.tertiary[60]};
      font-size: ${props.theme.size.fontSize[3]};
      line-height: ${props.theme.size.lineHeight[3]};
    }
  }
`
);

const LineItem = (props) => {
  const { item } = props;
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext);

  const variantImage = item.variant.image ? (
    <img
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      height='60px'
    />
  ) : null;

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(
        (option) => `${option.name}: ${option.value} `
      )
    : null;

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id);
  };

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <Item theme={theme}>
          <Link to={`/product/${item.variant.product.handle}/`}>
            {variantImage}
          </Link>
          <div className='product-info'>
            <div className='title'>
              {item.title}
              {`  `}
              {item.variant.title === !'Default Title'
                ? item.variant.title
                : ''}
            </div>
            <div className='options'>
              <span className='qty'>{item.quantity}x</span>
              {selectedOptions}
            </div>
            <div className='price'>
              {getPrice(item.variant.price, checkout)}
            </div>
          </div>
          <ButtonOutline
            onClick={handleRemove}
            size={-1}
            shape={4}
            color='secondary'
          >
            Remove
          </ButtonOutline>
        </Item>
      )}
    </ThemeContext.Consumer>
  );
};

export default LineItem;
