import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import StoreContext from '../../context/StoreContext';
import ThemeContext from '../../context/ThemeContext';

import LineItem from './LineItem';
import Button from '../Button';

const ShoppingCart = styled.div(
  (props) => `
  padding: 0 ${props.theme.size.base * 4}px ${props.theme.size.base * 4}px;
  .totals {
    padding: ${props.theme.size.base * 4}px;
    background: ${props.theme.color.background[15]};
    border-radius: 10px 10px 0 0;
}
  .checkout {
    padding: ${props.theme.size.base * 4}px;
    background: ${props.theme.color.background[40]};
    display: flex;
    direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 10px 10px;
  }
`
);

const Cart = () => {
  const breakpoints = useBreakpoint();
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  const lineItems = checkout.lineItems.map((item) => (
    <LineItem key={item.id.toString()} item={item} breakpoints={breakpoints} />
  ));

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <ShoppingCart breakpoints={breakpoints} theme={theme}>
          {lineItems}
          <div className='totals'>
            <div className='label'>Subtotal</div>
            <p className='value'>$ {checkout.subtotalPrice}</p>
            <div className='label'>Taxes</div>
            <p className='value'>$ {checkout.totalTax}</p>
            <div className='label'>Total</div>
            <p className='value'>$ {checkout.totalPrice}</p>
          </div>
          <div className='checkout'>
            <Button
              size={1}
              onClick={handleCheckout}
              disabled={checkout.lineItems.length === 0}
              shape={4}
            >
              Checkout
            </Button>
          </div>
        </ShoppingCart>
      )}
    </ThemeContext.Consumer>
  );
};

export default Cart;
