import React from 'react';

import Shopbar from '../components/Shopbar';
import RouterTracker from '../components/RouterTracker';
import Cart from '../components/Cart';
import NotFoundPage from './404';

const CartPage = ({ location }) => (
  <div className='body'>
    <section className='typesetter'>
      {process.env.NODE_ENV === 'development' ? (
        <div className='content'>
          <RouterTracker />
          <Shopbar location={location} />
          <Cart />
        </div>
      ) : (
        <NotFoundPage />
      )}
    </section>
  </div>
);

export default CartPage;
